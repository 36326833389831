import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  arrayUnion,
  getDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import {
  useToast,
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Switch,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useBreakpointValue,
  Box,
  Text,
  HStack,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
  useColorModeValue,
  Image,
  AspectRatio,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { FaCamera, FaPlus, FaTrash, FaCheck, FaArrowRight, FaTimes } from 'react-icons/fa';
import { db, storage } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import StarRating from '../shared/StarRating';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Capacitor } from '@capacitor/core';
import { debounce } from 'lodash';
import { motion, useAnimation } from 'framer-motion';
import { Timestamp } from 'firebase/firestore';

const MotionBox = motion(Box);
const MotionModalContent = motion(ModalContent);

const SwipeToFinish = ({ onFinish }) => {
  const [value, setValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const controls = useAnimation();
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const thumbColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.400');

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue >= 95) {
      onFinish();
      // Reset after animation
      setTimeout(() => setValue(0), 500);
    }
  };

  return (
    <Box
      p={3}
      bg={useColorModeValue('blue.50', 'blue.900')}
      borderRadius="full"
      position="relative"
      overflow="hidden"
      border="1px solid"
      borderColor={useColorModeValue('blue.200', 'blue.700')}
      boxShadow="sm"
    >
      <Text
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        fontSize="sm"
        color={useColorModeValue('blue.600', 'blue.200')}
        fontWeight="medium"
        pointerEvents="none"
        opacity={isDragging ? 0 : 0.8}
        transition="opacity 0.2s"
        zIndex={1}
      >
        Swipe to finish coffee
      </Text>
      
      <Slider
        value={value}
        onChange={handleChange}
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        onTouchStart={() => setIsDragging(true)}
        onTouchEnd={() => setIsDragging(false)}
        min={0}
        max={100}
        step={1}
      >
        <SliderTrack bg="transparent">
          <SliderFilledTrack bg="transparent" />
        </SliderTrack>
        <SliderThumb
          boxSize={10}
          bg={thumbColor}
          shadow="lg"
          _focus={{ boxShadow: "none" }}
        >
          <Icon
            as={value >= 95 ? FaCheck : FaArrowRight}
            color={value >= 95 ? "green.500" : "blue.500"}
          />
        </SliderThumb>
      </Slider>
    </Box>
  );
};

const AddCoffeeForm = ({ isOpen, onClose, onSuccess, editCoffee = null }) => {
  const { user } = useAuth();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('white', 'gray.800');
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const cancelRef = React.useRef();
  const controls = useAnimation();
  const [isDragging, setIsDragging] = useState(false);
  const dragThreshold = 100; // pixels to trigger close
  const initialRef = useRef();

  const defaultFormData = {
    name: '',
    roaster: '',
    roastLevel: 'Medium',
    photo: null,
    photoURL: null,
    roastDate: '',
    openDate: new Date().toISOString().split('T')[0],
    price: '',
    origin: '',
    process: 'Washed',
    tasting_notes: [],
    notes: '',
    isEspresso: false,
    rating: 0,
    status: 'active',
    finishedDate: null,
    remainingGrams: 250,
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    if (editCoffee) {
      setFormData({
        name: editCoffee.name || '',
        roaster: editCoffee.roaster || '',
        roastLevel: editCoffee.roastLevel || 'Medium',
        photoURL: editCoffee.photoURL || null,
        roastDate: editCoffee.roastDate || '',
        openDate: editCoffee.openDate || new Date().toISOString().split('T')[0],
        price: editCoffee.price || '',
        origin: editCoffee.origin || '',
        process: editCoffee.process || 'Washed',
        tasting_notes: editCoffee.tasting_notes || [],
        notes: editCoffee.notes || '',
        isEspresso: editCoffee.isEspresso || false,
        rating: editCoffee.rating || 0,
        status: editCoffee.status || 'active',
        finishedDate: editCoffee.finishedDate || null,
        remainingGrams: editCoffee.remainingGrams || 250,
      });
    }
  }, [editCoffee]);

  const searchExistingCoffee = debounce(async (name) => {
    if (!name || editCoffee) return; // Don't search if we're editing
    
    try {
      const q = query(
        collection(db, 'coffeeInventory'),
        where('name', '>=', name),
        where('name', '<=', name + '\uf8ff')
      );
      
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const existingCoffee = querySnapshot.docs[0].data();
        toast({
          title: 'Coffee found',
          description: 'Pre-filling details from existing coffee',
          status: 'info',
          duration: 3000,
        });
        
        setFormData(prev => ({
          ...prev,
          roaster: existingCoffee.roaster || prev.roaster,
          roastLevel: existingCoffee.roastLevel || prev.roastLevel,
          origin: existingCoffee.origin || prev.origin,
          process: existingCoffee.process || prev.process,
          tasting_notes: existingCoffee.tasting_notes || prev.tasting_notes,
        }));
      }
    } catch (error) {
      console.error('Error searching coffee:', error);
    }
  }, 500);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));

    // Search for existing coffee when name changes
    if (name === 'name' && value.length > 2) {
      searchExistingCoffee(value);
    }
  };

  const handleRatingChange = (newRating) => {
    setFormData(prev => ({
      ...prev,
      rating: newRating
    }));
  };

  const handlePhotoSelect = async (e) => {
    e.stopPropagation();
    try {
      if (Capacitor.isNativePlatform()) {
        const { Camera } = await import('@capacitor/camera');
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: 'base64',
          source: 'PROMPT'
        });

        const response = await fetch(`data:image/jpeg;base64,${image.base64String}`);
        const blob = await response.blob();
        
        setFormData(prev => ({
          ...prev,
          photo: blob,
          photoURL: URL.createObjectURL(blob)
        }));
      } else {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (e) => {
          const file = e.target.files[0];
          if (file) {
            setFormData(prev => ({
              ...prev,
              photo: file,
              photoURL: URL.createObjectURL(file)
            }));
          }
        };
        input.click();
      }
    } catch (error) {
      console.error('Error selecting photo:', error);
      toast({
        title: 'Error selecting photo',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleAddTag = () => {
    if (newTag.trim() && !formData.tasting_notes.includes(newTag.trim())) {
      setFormData(prev => ({
        ...prev,
        tasting_notes: [...prev.tasting_notes, newTag.trim()]
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tasting_notes: prev.tasting_notes.filter(tag => tag !== tagToRemove)
    }));
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, 'coffeeInventory', editCoffee.id));
      
      toast({
        title: 'Coffee deleted',
        status: 'success',
        duration: 3000,
      });
      
      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error deleting coffee:', error);
      toast({
        title: 'Error deleting coffee',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
      setIsDeleteAlertOpen(false);
    }
  };

  const handleFinishSwipe = () => {
    const now = new Date();
    setFormData(prev => ({
      ...prev,
      status: 'finished',
      finishedDate: now,
      updatedAt: now
    }));
    
    toast({
      title: "Coffee finished!",
      description: "Don't forget to rate it before saving.",
      status: "success",
      duration: 3000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let photoURL = formData.photoURL;

      // Upload photo if there's a new one
      if (formData.photo && formData.photo instanceof Blob) {
        const photoRef = ref(
          storage, 
          `coffee-photos/${user.uid}/${Date.now()}`
        );
        
        const uploadResult = await uploadBytes(photoRef, formData.photo);
        photoURL = await getDownloadURL(uploadResult.ref);
      }

      // Validate required fields
      if (!formData.name || !formData.roaster) {
        toast({
          title: 'Required fields missing',
          description: 'Please fill in coffee name and roaster',
          status: 'warning',
          duration: 3000,
        });
        return;
      }

      // If coffee is finished, validate rating
      if (formData.status === 'finished' && !formData.rating) {
        toast({
          title: 'Rating required',
          description: 'Please rate your coffee before marking it as finished',
          status: 'warning',
          duration: 3000,
        });
        return;
      }

      const now = new Date();
      const coffeeData = {
        ...formData,
        userId: user.uid,
        userName: user.displayName || 'Anonymous',
        userPhotoURL: user.photoURL,
        updatedAt: now,
        photoURL,
        pricePerUnit: parseFloat(formData.price) || 0,
        status: formData.status || 'active',
        finishedDate: formData.status === 'finished' ? (formData.finishedDate || now) : null,
        tasting_notes: formData.tasting_notes,
        rating: parseFloat(formData.rating) || 0
      };
      delete coffeeData.photo;
      delete coffeeData.id;

      if (editCoffee) {
        // Update existing coffee
        await updateDoc(doc(db, 'coffeeInventory', editCoffee.id), coffeeData);
      } else {
        // Add new coffee
        coffeeData.createdAt = now;
        const coffeeRef = await addDoc(collection(db, 'coffeeInventory'), coffeeData);

        // Update user's coffee inventory
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          await updateDoc(userRef, {
            coffeeInventory: arrayUnion(coffeeRef.id)
          });
        } else {
          await updateDoc(userRef, {
            coffeeInventory: [coffeeRef.id]
          });
        }
      }

      // Trigger haptic feedback on mobile
      if (Capacitor.isNativePlatform()) {
        try {
          const { Haptics } = await import('@capacitor/haptics');
          await Haptics.impact({ style: 'medium' });
        } catch (error) {
          console.debug('Haptics not available:', error);
        }
      }

      toast({
        title: editCoffee ? 'Coffee updated successfully' : 'Coffee added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error saving coffee:', error);
      toast({
        title: 'Error saving coffee',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async () => {
    if (!formData.id) return;
    
    const finishedDate = new Date();
    const updatedCoffee = {
      ...formData,
      status: 'finished',
      finishedDate: Timestamp.fromDate(finishedDate),
    };

    // Optimistically update the UI
    setFormData(updatedCoffee);
    onClose();

    try {
      await updateDoc(doc(db, 'coffeeInventory', formData.id), {
        status: 'finished',
        finishedDate: Timestamp.fromDate(finishedDate),
      });
    } catch (error) {
      console.error('Error updating coffee:', error);
      // Revert the optimistic update if the server update fails
      setFormData(formData);
      toast({
        title: 'Error updating coffee',
        description: 'Please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDragEnd = (_, info) => {
    if (info.offset.y > dragThreshold) {
      onClose();
    } else {
      controls.start({ y: 0 });
    }
    setIsDragging(false);
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        initialFocusRef={initialRef}
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <MotionModalContent 
          mx={4}
          mt={0}
          drag="y"
          dragConstraints={{ top: 0 }}
          dragElastic={0.7}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={handleDragEnd}
          animate={controls}
          maxH="95vh"
          overflowY="auto"
        >
          {/* Drag Handle */}
          <Box 
            w="40px" 
            h="4px" 
            bg={useColorModeValue('gray.300', 'gray.600')}
            borderRadius="full" 
            mx="auto" 
            mt={2}
            mb={1}
          />
          <ModalHeader p={4}>
            <HStack justify="center" align="center" position="relative">
              <Box position="absolute" left={0}>
                <IconButton
                  icon={<FaTimes />}
                  variant="ghost"
                  onClick={onClose}
                  aria-label="Close"
                  size="sm"
                  color={useColorModeValue('gray.500', 'gray.400')}
                />
              </Box>
              <Text 
                fontSize="lg" 
                fontWeight="bold"
                color={useColorModeValue('gray.800', 'white')}
              >
                {editCoffee ? 'Edit Coffee' : 'Add Coffee'}
              </Text>
            </HStack>
          </ModalHeader>

          <ModalBody pt={0} pb={6}>
            <VStack spacing={4} align="stretch">
              {/* Photo Upload Section */}
              <Box>
                {formData.photoURL ? (
                  <Box position="relative">
                    <Image
                      src={formData.photoURL}
                      alt="Coffee"
                      objectFit="cover"
                      borderRadius="lg"
                      height="200px"
                      width="100%"
                    />
                    <IconButton
                      icon={<FaTimes />}
                      size="sm"
                      position="absolute"
                      top={2}
                      right={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        setFormData(prev => ({
                          ...prev,
                          photo: null,
                          photoURL: null
                        }));
                      }}
                      aria-label="Remove photo"
                      colorScheme="blackAlpha"
                      variant="unstyled"
                      color="white"
                      opacity={0.8}
                      _hover={{ opacity: 1 }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    />
                  </Box>
                ) : (
                  <VStack py={8} spacing={2}>
                    <FaCamera size={24} />
                    <Text>Add Photo</Text>
                  </VStack>
                )}
              </Box>

              {/* Espresso Toggle - Keep at top */}
              <FormControl display="flex" alignItems="center" justifyContent="flex-end">
                <FormLabel mb="0" mr={3}>Espresso Roast</FormLabel>
                <Switch
                  name="isEspresso"
                  isChecked={formData.isEspresso}
                  onChange={handleInputChange}
                  size="lg"
                  colorScheme="orange"
                />
              </FormControl>

              {/* Basic Info Group */}
              <SimpleGrid columns={{ base: 2 }} spacing={4}>
                <FormControl>
                  <FormLabel>Coffee Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="e.g., Ethiopia Yirgacheffe"
                    size="lg"
                    fontSize="md"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Roaster</FormLabel>
                  <Input
                    name="roaster"
                    value={formData.roaster}
                    onChange={handleInputChange}
                    placeholder="e.g., Blue Bottle"
                    size="lg"
                    fontSize="md"
                  />
                </FormControl>
              </SimpleGrid>

              {/* Origin and Process Group */}
              <SimpleGrid columns={{ base: 2 }} spacing={4}>
                <FormControl>
                  <FormLabel>Origin</FormLabel>
                  <Input
                    name="origin"
                    value={formData.origin}
                    onChange={handleInputChange}
                    placeholder="e.g., Ethiopia"
                    size="lg"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Process</FormLabel>
                  <Select
                    name="process"
                    value={formData.process}
                    onChange={handleInputChange}
                    size="lg"
                  >
                    <option>Washed</option>
                    <option>Natural</option>
                    <option>Honey</option>
                    <option>Anaerobic</option>
                    <option>Other</option>

                  </Select>
                </FormControl>
              </SimpleGrid>

              {/* Roast Info and Dates Group */}
              <SimpleGrid columns={{ base: 2 }} spacing={4}>
                <FormControl>
                  <FormLabel>Roast Level</FormLabel>
                  <Select
                    name="roastLevel"
                    value={formData.roastLevel}
                    onChange={handleInputChange}
                    size="lg"
                  >
                    <option>Light</option>
                    <option>Medium-Light</option>
                    <option>Medium</option>
                    <option>Medium-Dark</option>
                    <option>Dark</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Open Date</FormLabel>
                  <Input
                    name="openDate"
                    type="date"
                    value={formData.openDate}
                    onChange={handleInputChange}
                    size="lg"
                  />
                </FormControl>
              </SimpleGrid>

              {/* Dates Group */}
              <SimpleGrid columns={{ base: 2 }} spacing={4}>
                <FormControl>
                  <FormLabel>Roast Date</FormLabel>
                  <Input
                    name="roastDate"
                    type="date"
                    value={formData.roastDate}
                    onChange={handleInputChange}
                    size="lg"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Open Date</FormLabel>
                  <Input
                    name="openDate"
                    type="date"
                    value={formData.openDate}
                    onChange={handleInputChange}
                    size="lg"
                  />
                </FormControl>
              </SimpleGrid>

              {/* Purchase Info Group */}
              <SimpleGrid columns={{ base: 2 }} spacing={4}>
                <FormControl>
                  <FormLabel>Amount (g)</FormLabel>
                  <NumberInput
                    name="remainingGrams"
                    value={formData.remainingGrams}
                    onChange={(value) => setFormData(prev => ({
                      ...prev,
                      remainingGrams: Number(value)
                    }))}
                    min={0}
                    max={2000}
                    size="lg"
                  >
                    <NumberInputField fontSize="2xl" textAlign="center" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Price (per 250g)</FormLabel>
                  <Input
                    name="price"
                    type="number"
                    value={formData.price}
                    onChange={handleInputChange}
                    placeholder="0.00"
                    size="lg"
                    fontSize="xl"
                    textAlign="center"
                  />
                </FormControl>
              </SimpleGrid>

              {/* Tasting Notes */}
              <FormControl>
                <FormLabel>Tasting Notes</FormLabel>
                <HStack mb={2}>
                  <Input
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="e.g., Chocolate, Fruity"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddTag();
                      }
                    }}
                  />
                  <IconButton
                    icon={<FaPlus />}
                    onClick={handleAddTag}
                    aria-label="Add tag"
                  />
                </HStack>
                <Box minH="40px">
                  <HStack spacing={2} flexWrap="wrap">
                    {formData.tasting_notes.map((tag, index) => (
                      <Tag
                        key={index}
                        size={isMobile ? "lg" : "md"}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                      >
                        <TagLabel>{tag}</TagLabel>
                        <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                      </Tag>
                    ))}
                  </HStack>
                </Box>
              </FormControl>

              {/* Additional Notes */}
              <FormControl>
                <FormLabel>Additional Notes</FormLabel>
                <Textarea
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                  placeholder="Any additional notes about this coffee..."
                  rows={3}
                  fontSize="md"
                />
              </FormControl>

              {/* Rating */}
              <FormControl>
                <FormLabel>Rating</FormLabel>
                <Box p={2} textAlign="center">
                  <StarRating
                    rating={formData.rating}
                    onChange={handleRatingChange}
                    size={28}
                    isEditable
                    spacing={2}
                  />
                </Box>
              </FormControl>

              {/* Status Section */}
              {formData.status !== 'finished' ? (
                <SwipeToFinish onFinish={handleFinishSwipe} />
              ) : (
                <Box
                  p={4}
                  bg={useColorModeValue('green.50', 'green.900')}
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="green.200"
                >
                  <HStack spacing={3}>
                    <Icon as={FaCheck} color="green.500" boxSize={5} />
                    <VStack align="start" spacing={0}>
                      <Text fontWeight="medium">Coffee Finished</Text>
                      <Text fontSize="sm" color={useColorModeValue('gray.600', 'gray.400')}>
                        {new Date(formData.finishedDate).toLocaleDateString()}
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
              )}

              {/* Rating Section - Show when coffee is finished */}
              {formData.status === 'finished' && (
                <Box
                  p={4}
                  bg={useColorModeValue('gray.50', 'gray.700')}
                  borderRadius="lg"
                  borderWidth="1px"
                >
                  <FormControl>
                    <FormLabel fontSize="lg">Final Rating</FormLabel>
                    <VStack align="start" spacing={2}>
                      <StarRating
                        rating={formData.rating}
                        onChange={handleRatingChange}
                        size={14}
                        isEditable
                        spacing={1}
                      />
                      <Text fontSize="sm" color="gray.500">
                        Rate this coffee before saving
                      </Text>
                    </VStack>
                  </FormControl>
                </Box>
              )}

              {/* Action Buttons */}
              <HStack 
                spacing={4} 
                position={isMobile ? "fixed" : "relative"}
                bottom={isMobile ? 0 : undefined}
                left={isMobile ? 0 : undefined}
                right={isMobile ? 0 : undefined}
                p={isMobile ? 4 : 0}
                bg={isMobile ? bgColor : "transparent"}
                borderTopWidth={isMobile ? 1 : 0}
                zIndex={2}
              >
                {editCoffee && (
                  <Button
                    colorScheme="red"
                    variant="ghost"
                    leftIcon={<FaTrash />}
                    onClick={() => setIsDeleteAlertOpen(true)}
                    size={isMobile ? "lg" : "md"}
                    flex={isMobile ? 1 : undefined}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  colorScheme="blue"
                  onClick={handleSubmit}
                  isLoading={loading}
                  size={isMobile ? "lg" : "md"}
                  py={isMobile ? 7 : undefined}
                  flex={1}
                >
                  {editCoffee ? 'Update Coffee' : 'Add to Inventory'}
                </Button>
              </HStack>
              {isMobile && <Box h="80px" />} {/* Spacer for fixed buttons */}
            </VStack>
          </ModalBody>
        </MotionModalContent>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete Coffee</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this coffee? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AddCoffeeForm;

