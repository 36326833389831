import React from 'react';
import {
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaCoffee, FaMugHot, FaStore, FaBook } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const CreateMenu = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Menu>
      <MenuButton as="div">
        {children}
      </MenuButton>
      <MenuList>
        <MenuItem 
          icon={<FaCoffee />} 
          onClick={() => navigate('/inventory')}
        >
          Add Coffee
        </MenuItem>
        <MenuItem 
          icon={<FaMugHot />} 
          onClick={() => navigate('/brew-journal')}
        >
          Log Brew
        </MenuItem>
        <MenuItem 
          icon={<FaStore />} 
          onClick={() => navigate('/places')}
        >
          Add Café
        </MenuItem>
        <MenuItem 
          icon={<FaBook />} 
          onClick={() => navigate('/tasting')}
        >
          Tasting Notes
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CreateMenu;
